import React from "react";

import "./SendIcon.scss";

export default function SendIcon() {
  return (
    <svg
      viewBox="0 0 1024 1024"
      class="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="PackagesIcon"
    >
      <path
        d="M396 553.6L288 883.2l264-278.4 113.6 36.8c10.4 3.2 22.4-0.8 28-10.4L992 140.8 49.6 394.4c-23.2 6.4-24 38.4-1.6 45.6l163.2 53.6 76 389.6"
        fill="#FFFFFF"
      />
      <path
        d="M288 891.2c-1.6 0-3.2-0.8-4.8-1.6-0.8 0-0.8-0.8-0.8-0.8-0.8-0.8-1.6-2.4-2.4-4l-75.2-384.8L46.4 448C32 444 24 431.2 24 416.8c0-14.4 9.6-26.4 24-30.4l942.4-254.4c3.2-0.8 6.4 0 8.8 3.2 1.6 2.4 2.4 6.4 0.8 8.8L700 636c-8 12.8-23.2 18.4-37.6 13.6l-108.8-35.2-260.8 275.2c-0.8 0.8-2.4 1.6-4 2.4 0-0.8-0.8-0.8-0.8-0.8z m108-345.6h2.4c4 1.6 6.4 5.6 4.8 10.4l-96.8 296 239.2-252.8c2.4-2.4 5.6-3.2 8-2.4l113.6 36.8c7.2 2.4 14.4-0.8 18.4-7.2l288-474.4L52 402.4c-8.8 2.4-12 8.8-12 15.2s2.4 12.8 11.2 16l163.2 53.6c2.4 0.8 4.8 3.2 5.6 6.4l69.6 359.2 98.4-300c0.8-4.8 4-7.2 8-7.2z"
        fill="#6A576D"
      />
      <path
        d="M382.4 304.8l198.4 20.8 16.8 86.4 80-55.2 123.2 97.6L992 140.8z"
        fill="#99D9E6"
      />
      <path
        d="M800.8 462.4c-1.6 0-3.2-0.8-4.8-1.6L677.6 367.2l-75.2 51.2c-2.4 1.6-4.8 1.6-7.2 0.8-2.4-0.8-4-3.2-4.8-5.6l-16-80.8-192.8-20c-4 0-7.2-3.2-7.2-7.2s2.4-7.2 5.6-8l609.6-164c3.2-0.8 6.4 0 8.8 3.2 1.6 2.4 2.4 6.4 0.8 8.8L808 458.4c-0.8 1.6-3.2 3.2-5.6 4h-1.6zM677.6 348.8c1.6 0 2.4 0 3.2 0.8 0.8 0 0.8 0.8 1.6 0.8l116 92 176-288.8-548 148 155.2 16c3.2 0 6.4 3.2 7.2 6.4l14.4 74.4 69.6-48c1.6-0.8 3.2-1.6 4.8-1.6z"
        fill="#6A576D"
      />
      <path d="M288 883.2l264-278.4-156-51.2z" fill="#111318" />
      <path
        d="M992 140.8L211.2 492l76.8 391.2 264-278.4-156-51.2z"
        fill="#111318"
      />
      <path
        d="M288 891.2h-2.4c-3.2-0.8-4.8-3.2-5.6-6.4l-76-391.2c-0.8-4 0.8-7.2 4.8-8.8l780.8-351.2c4-1.6 8 0 10.4 3.2 2.4 4 0.8 8-2.4 10.4l-584 404 140.8 46.4c2.4 0.8 4.8 3.2 5.6 5.6 0.8 2.4 0 5.6-1.6 7.2l-264 278.4c-2.4 1.6-4.8 2.4-6.4 2.4z m-67.2-394.4l72 369.6L537.6 608l-144-47.2c-3.2-0.8-4.8-3.2-5.6-6.4-0.8-3.2 0.8-6.4 3.2-8L916 184 220.8 496.8z"
        fill="#6A576D"
      />
    </svg>
  );
}
