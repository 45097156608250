import React from "react";
import { Link } from "react-router-dom";
import Price from "../Price/Price";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";

import "./OrderItemsList.scss";
import { DELIVERY_FEE } from "../../constants/deliveryFee";

export default function OrderItemsList({ order }) {
  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const DesktopView = () => {
    const total = order.totalPrice + DELIVERY_FEE({ order });

    return (
      !isMobile && (
        <table className="OrderItemsList">
          <tbody>
            <tr>
              <td colSpan="5">
                <h3>Order Items:</h3>
              </td>
            </tr>
            <div className="OrderItemsList-List">
              {order.items.map((item) => (
                <Link
                  to={`/store/${item.food.parentId}/food/${item.food.id}`}
                  className="OrderItemsList-link"
                >
                  <div className="OrderItemsList-link-content">
                    <div className="OrderItemsList-link-imageContainer">
                      <img
                        src={`${item.food.imageUrl}`}
                        alt={item.food.name}
                        className="OrderItemsList-link-image"
                      />
                    </div>
                    <div>{item.food.name}</div>
                    <div>
                      <Price price={item.food.price} />
                    </div>
                    <div>x {item.quantity}</div>
                    <div>
                      <Price price={item.price} />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </tbody>
          <div className="OrderItemsList-link-totalPrice">
            <span>
              Item(s) Total: <Price price={order.totalPrice} />
            </span>
            <span>
              Delivery fee: <Price price={DELIVERY_FEE({ order })} />
            </span>
            <span className="OrderItemsList-link-totalPrice-main">
              Total: <Price price={total} />
            </span>
          </div>
        </table>
      )
    );
  };

  const MobileView = () => {
    const total = order.totalPrice + DELIVERY_FEE({ order });
    return (
      isMobile && (
        <table className="OrderItemsList">
          <tbody>
            <tr>
              <td colSpan="5">
                <h3>Order Items:</h3>
              </td>
            </tr>
            <div className="OrderItemsList-List">
              {order.items.map((item) => (
                <Link
                  to={`/store/${item.food.parentId}/food/${item.food.id}`}
                  className="OrderItemsList-link"
                >
                  <div className="OrderItemsList-link-content">
                    <div className="OrderItemsList-link-imageContainer">
                      <img
                        src={`${item.food.imageUrl}`}
                        alt={item.food.name}
                        className="OrderItemsList-link-image"
                      />
                    </div>
                    <div className="OrderItemsList-link-right">
                      <div className="OrderItemsList-link-rightTop">
                        <div className="OrderItemsList-link-rightTop-left name">
                          {item.food.name}
                        </div>
                        <div>x {item.quantity}</div>
                      </div>

                      <div className="OrderItemsList-link-right-price">
                        <Price price={item.price} />
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </tbody>
          <div className="OrderItemsList-link-totalPrice">
            <span>
              Item(s) Total: <Price price={order.totalPrice} />
            </span>
            <span>
              Delivery fee: <Price price={DELIVERY_FEE({ order })} />
            </span>
            <span className="OrderItemsList-link-totalPrice-main">
              Total: <Price price={total} />
            </span>
          </div>
        </table>
      )
    );
  };

  return (
    <>
      {DesktopView()}
      {MobileView()}
    </>
  );
}
