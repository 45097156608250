import React from "react";
import { Helmet } from "react-helmet";

export default function ReactHelmet({
  title,
  description,
  ogurl,
  ogtitle,
  ogdescription,
  ogtype,
  ogimagealt,
  ogsitename,
  oglocale,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={ogurl} />
      <meta property="og:title" content={ogtitle} />
      <meta property="og:description" content={ogdescription} />
      <meta property="og:type" content={ogtype} />
      <meta property="og:image:alt" content={ogimagealt} />
      <meta property="og:site_name" content={ogsitename} />
      <meta property="og:locale" content={oglocale} />
    </Helmet>
  );
}
