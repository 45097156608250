import React from "react";

import "./Logo.scss";

export default function Logo() {
  return (
    <div className="Logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1490 1710"
        version="1.1"
      >
        <path d="" stroke="none" fill="#fc5c1c" fill-rule="evenodd" />
        <path
          d="M 0 276.089 L 0 551.232 3.437 555.866 C 8.627 562.866, 26.403 579.937, 36.148 587.283 C 56.648 602.735, 61.145 605.125, 90.500 616.174 C 103.166 620.941, 104.116 621.135, 127.500 623.735 L 151.500 626.403 305.096 626.732 L 458.692 627.060 459.346 616.375 C 459.706 610.497, 460 537.816, 460 454.860 L 460 304.032 466.367 308.266 C 473.847 313.240, 471.010 311.033, 515.515 346.500 C 534.839 361.900, 676.051 474.395, 829.319 596.489 L 1107.988 818.479 1104.744 821.646 C 1102.960 823.388, 963.575 945.506, 795 1093.020 L 488.500 1361.226 488.208 1347.863 C 488.048 1340.513, 487.144 1279.746, 486.200 1212.825 C 485.256 1145.904, 484.238 1090.905, 483.939 1090.606 C 483.640 1090.307, 412.994 1090.547, 326.948 1091.141 C 151.565 1092.350, 137.978 1092.186, 117.500 1088.610 C 99.573 1085.480, 79.216 1079.973, 66.500 1074.815 C 52.386 1069.090, 26.547 1056.943, 13.014 1049.671 C 6.696 1046.277, 1.183 1043.350, 0.762 1043.166 C 0.341 1042.983, 0.110 1192.833, 0.249 1376.166 L 0.500 1709.500 290.500 1709.790 C 570.238 1710.070, 650.751 1709.499, 686.500 1706.982 C 828.235 1697.002, 943.797 1667.187, 1056.500 1611.523 C 1143.436 1568.584, 1216.412 1514.494, 1279.815 1446 C 1365.270 1353.684, 1427.455 1235.577, 1460.966 1101.941 C 1499.537 948.131, 1499.526 761.850, 1460.939 607.954 C 1438.862 519.903, 1403.210 435.936, 1357.994 365.500 C 1275.484 236.967, 1159.041 140.366, 1010.418 77.152 C 930.536 43.176, 846.383 20.949, 754.500 9.557 C 736.050 7.269, 723.064 5.976, 687 2.836 C 677.095 1.973, 583.821 1.565, 336.750 1.303 L 0 0.946 0 276.089"
          stroke="none"
          fill="#fc5c14"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  );
}
