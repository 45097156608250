import React from "react";
import { Link } from "react-router-dom";

import "./Packages.scss";

export default function Packages({ text, link, icon, arrow, className }) {
  return (
    <div className="Packages-thumbnail">
      <Link to={link} className={className}>
        <div className="Packages-thumbnail-text">
          <span>{text}</span>
          <span className="Packages-thumbnail-text-arrow">{arrow}</span>
        </div>
        <div className="Packages-thumbnail-imageContainer">{icon}</div>
      </Link>
    </div>
  );
}
