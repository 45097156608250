import React from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

import "./ProfilePage.scss";
import Price from "../../components/Price/Price";
import { Link } from "react-router-dom";
import EditProfile from "../../components/EditProfile/EditProfile";

export default function ProfilePage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { user, updateProfile } = useAuth();

  const submit = (user) => {
    updateProfile(user);
  };

  const zero = 0;

  const userPhone = zero.toString() + user.phone.toString();

  return (
    <div className="MainProfilePage">
      <div></div>
      <div className="MainProfilePage-banner">
        <h2>Welcome, {user.firstname}</h2>
      </div>
      <div className="MainProfilePage-content">
        <div className="MainProfilePage-content-down">
          <div className="MainProfilePage-content-ref">
            <strong>Referral code:</strong>
            <span className="MainProfilePage-content-refCode">
              <div>{user.firstname.toUpperCase()}</div>
              <div>{user.lastname.toUpperCase()}</div>
            </span>
          </div>
          <div className="MainProfilePage-content-balance">
            <div>
              <strong>Deliepoint balance:</strong>{" "}
              <Price price={user.balance} />
            </div>

            <span className="MainProfilePage-topUp">
              <Link to="/topup">Topup</Link>
            </span>
          </div>
          <div className="MainProfilePage-editProfile">
            <Link to="/editProfile">Edit profile</Link>
          </div>
        </div>
      </div>
      {/* <div className="ProfilePage-content">
        <Title title="Update Profile" />
        <form onSubmit={handleSubmit(submit)}>
          <Input
            className="ProfilePage-input"
            defaultValue={user.firstname}
            type="text"
            label="Firstname"
            {...register("firstname", {
              required: true,
              minLength: 1,
            })}
            error={errors.firstname}
          />
          <Input
            className="ProfilePage-input"
            defaultValue={user.lastname}
            type="text"
            label="Lastname"
            {...register("lastname", {
              required: true,
              minLength: 1,
            })}
            error={errors.lastname}
          />
          <Input
            className="ProfilePage-input"
            defaultValue={userPhone}
            type="tel"
            label="Phone Number"
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9]{11}$/,
                message: "Enter a valid phone number",
              },
            })}
            error={errors.phone}
          />
          <Input
            className="ProfilePage-input"
            defaultValue={user.address}
            type="text"
            label="Address"
            {...register("address", {
              required: true,
              minLength: 5,
            })}
            error={errors.address}
          />
          <Button type="submit" text="Update" className="ProfilePage-button" />
        </form>

        <ChangePassword />
      </div> */}
    </div>
  );
}
