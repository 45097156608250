import React from "react";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";

import "./Thumbnails.scss";

export default function Thumbnails({ stores }) {
  const reversedStores = stores.slice(0).reverse();
  return (
    <ul className="Thumbnails">
      {reversedStores.map((store) => (
        <li key={store.id} className="Thumbnails-store">
          <Link to={`/store/${store.id}`} className="Thumbnails-store-link">
            <div className="Thumbnails-imageContainer">
              <img
                src={`${store.imageUrl}`}
                alt={store.name}
                className="Thumbnails-imageContainer-image"
              />
            </div>
            <div className="Thumbnails-store-down">
              <div className="Thumbnails-store-nameStar">
                <div className="Thumbnails-store-nameStar-name name">
                  {store.name}
                </div>
                {/* <div>
                  <StarRating stars={store.star} />
                </div> */}
              </div>
              {/* <span className={`Favourite ${!store.favourite && "notFavourite"}`}>
              ❤
            </span> */}
              <div className="Thumbnails-store-tags">
                <div>
                  {store.tags.map((tag) => (
                    <span key={tag} className="Thumbnails-store-tag">
                      {tag}
                    </span>
                  ))}
                </div>
                <div>
                  <StarRating stars={store.star} />
                </div>
              </div>
              {/* <div>
              <Price price={store.price} />
            </div> */}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
