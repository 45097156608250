import React from "react";

import "./Price.scss";

export default function Price({ price, locale, currency }) {
  const formatPrice = () =>
    new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(price);

  return <span className="Price">{formatPrice()}</span>;
}

Price.defaultProps = {
  locale: "en-NG",
  currency: "NGN",
};
