import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { DELIVERY_FEE } from "../../constants/deliveryFee";
import Price from "../Price/Price";

import "./BalancePayButton.scss";

export default function BalancePayButton({ order }) {
  const { user, useBalance } = useAuth();

  const priceTag = (price) => {
    return <Price price={price} />;
  };

  const useBalanceToPay = () => {
    const userBalance = user.balance;
    const totalAmount = order.totalPrice + DELIVERY_FEE({ order });

    useBalance(userBalance, totalAmount);
  };
  return (
    <div
      onClick={useBalanceToPay}
      className="CartPage-button Button BalancePayButton"
    >
      Pay with balance (<span>{priceTag(user.balance)})</span>
    </div>
  );
}
