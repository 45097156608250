import React, { useEffect } from "react";
import Title from "../../components/Title/Title";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";
import { useSearchParams, useNavigate, Link } from "react-router-dom";

import "./ForgotPasswordPage.scss";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

export default function ForgotPasswordPage() {
  const auth = useAuth();
  const { user, verifyEmail } = auth;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!user) return;
    returnUrl ? navigate(returnUrl) : navigate("/");
  }, [user, navigate, returnUrl]);

  const submit = async ({ email }) => {
    await verifyEmail(email);
  };

  return (
    <div className="ForgotPasswordPage">
      <div className="ForgotPasswordPage-content">
        <Title title="Reset your password" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            className="ForgotPasswordPage-input"
            type="email"
            placeholder="Enter your email address"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: "Email is not valid",
              },
            })}
            error={errors.email}
          />
          <Button
            type="submit"
            text="Request password reset"
            className="ForgotPasswordPage-button"
          />
          <div className="ForgotPasswordPage-loginRedirect">
            Remember your password? &nbsp;
            <Link to={`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`}>
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
