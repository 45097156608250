import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./Search.scss";
import SearchIcon from "../SearchIcon/SearchIcon";

export default function Search({ closeSearch }) {
  const [term, setTerm] = useState("");
  const navigate = useNavigate();
  const { searchTerm } = useParams();

  useEffect(() => {
    setTerm(searchTerm ?? "");
  }, [searchTerm]);

  const search = async () => {
    term ? navigate("/search/store/" + term) : navigate("/");
    term && closeSearch();
  };

  return (
    <div className="Search">
      <div className="Search-items">
        <input
          type="text"
          placeholder="Search Stores"
          onChange={(e) => setTerm(e.target.value)}
          onKeyUp={(e) => e.key === "Enter" && search()}
          value={term}
          className="Search-input"
        />
        <div onClick={search} className="Search-icon">
          <SearchIcon />
        </div>
      </div>
    </div>
  );
}
