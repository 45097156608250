import React, { useEffect } from "react";
import Title from "../../components/Title/Title";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {
  Link,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";

export default function ForgotPasswordPage() {
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  const { user, resetPassword } = useAuth();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    returnUrl ? navigate(returnUrl) : navigate("/");
  }, [user, returnUrl, navigate]);

  const { id, passwordResetToken } = useParams();
  const submit = async (passwords) => {
    await resetPassword(passwords, id, passwordResetToken);
    navigate(`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`);
  };

  return (
    <div className="ResetPasswordPage">
      <div className="ResetPasswordPage-content">
        <Title title="Reset your password" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            className="ResetPasswordPage-input"
            type="password"
            label="New Password"
            {...register("newPassword", {
              required: true,
              minLength: 5,
            })}
            error={errors.newPassword}
          />

          <Input
            className="ResetPasswordPage-input"
            type="password"
            label="Confirm Password"
            {...register("confirmNewPassword", {
              required: true,
              validate: (value) =>
                value != getValues("newPassword")
                  ? "Passwords do not match"
                  : true,
            })}
            error={errors.confirmNewPassword}
          />

          <Button
            type="submit"
            text="Reset password"
            className="ResetPasswordPage-button"
          />
          <div className="ResetPasswordPage-loginRedirect">
            Remember your password? &nbsp;
            <Link to={`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`}>
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
