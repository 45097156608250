import React from "react";
import { Link, useParams } from "react-router-dom";

import "./FoodTags.scss";

export default function FoodTags({ foodTags, store }) {
  const { foodTag } = useParams();
  const tagName = foodTag;

  const allTag = () => {
    if (!foodTag || foodTag == "All") return "selected";
    return "";
  };

  return (
    <div className="FoodTags">
      <Link
        name={allTag()}
        className={`FoodTags-links`}
        to={`/store/${store.id}/foodTag/All`}
      >
        All
      </Link>
      {foodTags?.map((foodTag) => (
        <Link
          name={foodTag == tagName ? "selected" : ""}
          className={`FoodTags-links`}
          key={foodTag}
          to={`/store/${store.id}/foodTag/${foodTag}`}
        >
          {foodTag}
        </Link>
      ))}
    </div>
  );
}
