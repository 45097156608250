import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NotFound from "../../components/NotFound/NotFound";
import OrderItemsList from "../../components/OrderItemsList/OrderItemsList";
import { trackOrderById } from "../../services/orderService";
import DateTime from "../../components/DateTime/DateTime";

import "./OrderTrackPage.scss";

export default function OrderTrackPage() {
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    orderId &&
      trackOrderById(orderId).then((order) => {
        setOrder(order);
      });
  }, []);

  if (!orderId)
    return <NotFound message="Order Not Found" linkText="Go To Home Page" />;

  return (
    order && (
      <div className="OrderTrackPage">
        <div>
          <div className="OrderTrackPage-id">Order #{order.id}</div>
          <div>
            <div className="OrderTrackPage-details">
              <strong>Date:</strong>
              <DateTime date={order.createdAt} />
            </div>
            <div className="OrderTrackPage-details">
              <strong>Name:</strong>
              <div className="OrderTrackPage-details-name">
                <span>{order.firstname}</span>
                <span>{order.lastname}</span>
              </div>
            </div>
            <div className="OrderTrackPage-details">
              <strong>Address:</strong>
              {order.address}
            </div>
            <div className="OrderTrackPage-details">
              <strong>Status:</strong>
              {order.status}
            </div>
            {order.paymentId && (
              <div>
                <strong>Payment ID: </strong>
                {order.paymentId}
              </div>
            )}
          </div>

          <OrderItemsList order={order} />
        </div>

        {order.status === "NEW" && (
          <div>
            <Link to="/payment" className="OrderTrackPage-button Button">
              Go to payment
            </Link>
          </div>
        )}
      </div>
    )
  );
}
