import { useState, createContext, useContext } from "react";
import * as userService from "../services/userService";
import { toast } from "react-toastify";
import { pay } from "../services/orderService";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(userService.getUser());
  const navigate = useNavigate();

  const login = async (email, password) => {
    try {
      const user = await userService.login(email, password);
      setUser(user);
      toast.success(`Welcome back ${user.firstname}! 🙌`);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const register = async (data) => {
    try {
      const user = await userService.register(data);
      setUser(user);
      toast.success(`Welcome to deliepoint, ${user.firstname}! 🎉`);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const verifyEmail = async (email) => {
    try {
      const user = await userService.verifyEmail(email);
      setUser(user);
      toast.success(
        `A reset link has been sent to ${email} if there is an account for this email.`
      );
    } catch (err) {
      toast.success(
        `A reset link has been sent to ${email} if there is an account for this email.`
      );
    }
  };

  const logout = () => {
    userService.logout();
    setUser(null);
    toast.success("Logout successful. See you soon! 🫡");
  };

  const updateProfile = async (user) => {
    const updatedUser = await userService.updateProfile(user);
    toast.success("Profile updated successfully!");
    if (updatedUser) setUser(updatedUser);
  };

  const updateBalance = async (user) => {
    const updatedUser = await userService.updateBalance(user);
    toast.success("Balance topup successful!");
    if (updatedUser) setUser(updatedUser);
  };

  const useBalance = async (userBalance, totalAmount) => {
    if (userBalance < totalAmount) {
      toast.error("Insufficient balance, please top up to continue", "Error");
      return;
    } else {
      const user = userBalance - totalAmount;
      const text = "Proceed to pay with your deliepoint balance?";

      if (window.confirm(text) == true) {
        const updatedUser = await userService.useBalance(user);
        const orderId = await pay();
        toast.success("Payment successful!", "Success");
        navigate("/track/" + orderId);
        if (updatedUser) setUser(updatedUser);
      }
    }
  };

  const changePassword = async (passwords) => {
    try {
      await userService.changePassword(passwords);
      logout();
      toast.success("Password changed successfully, please login again!");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const resetPassword = async (passwords, id, passwordResetToken) => {
    try {
      await userService.resetPassword(passwords, id, passwordResetToken);
      toast.success(
        "Password reset successful, please login using your new password"
      );
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        verifyEmail,
        register,
        updateProfile,
        changePassword,
        resetPassword,
        updateBalance,
        useBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
