import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getById } from "../../services/storeService";
import FoodThumbnails from "../../components/FoodThumbnails/FoodThumbnails";
import NotFound from "../../components/NotFound/NotFound";

import "./StorePage.scss";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";
import FoodTags from "../../components/FoodTags/FoodTags";
import GoBack from "../../components/GoBack/GoBack";

export default function StorePage() {
  const [store, setStore] = useState({});
  const { id } = useParams();
  const { foodTag } = useParams();

  const foodTagsFlatMap = store.foods?.flatMap((item) => item.tags);

  const foodTags = foodTagsFlatMap?.filter(
    (item, index) => foodTagsFlatMap.indexOf(item) === index
  );

  const finalFoodTags = foodTags?.filter((item) => {
    return item !== undefined;
  });

  const displayedFood = () => {
    if (!foodTag) return store.foods;
    else if (foodTag && foodTag === "All") {
      return store.foods;
    } else if (foodTag && foodTag != "All") {
      return store.foods?.filter((item) => item.tags?.includes(foodTag));
    }
  };

  const storeLocation = "Port Harcourt";

  const storeHero = () => {
    return (
      <div>
        <ReactHelmet
          title={`${store.name} - Menu & Delivery in ${storeLocation} - Order Online | Deliepoint`}
          description={`Looking for ${store.name} in ${storeLocation}? Deliepoint has you covered! Browse our extensive food menu, order food online, and enjoy fast and reliable delivery right to your door. Say goodbye to long queues and waiting time with Deliepoint! `}
          // ogurl={`https://deliepoint.com/store/${store.id}`}
          // ogtitle={`Deliepoint - ${store.name}`}
          // ogdescription={`Looking for ${store.name} in ${storeLocation}? Deliepoint has you covered! Browse our extensive food menu, order food online, and enjoy fast and reliable delivery right to your door. Say goodbye to long queues and waiting time with Deliepoint! `}
          // ogtype="website"
          // ogimagealt={store.name}
          // ogsitename="Delipoint"
          // oglocale="en_NG"
        />
        <div className="StorePage-imageContainer">
          <img
            src={`${store.imageUrl}`}
            alt={store.name}
            className="StorePage-imageContainer-image"
          />
        </div>
        <div className="StorePage-storeHero">
          <h3 className="StorePage-storeHero-name">{store.name}</h3>
          <div className="StorePage-storeHero-info">
            <p>{store.openingTime1}</p>
            <p>{store.openingTime2}</p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getById(id).then(setStore);
  }, [id]);
  return (
    <div className="StorePage">
      {!store ? (
        <NotFound />
      ) : (
        <div>
          <GoBack title="All restaurants" home />
          {storeHero()}
          <FoodTags foodTags={finalFoodTags} store={store} />
          <FoodThumbnails foods={displayedFood()} store={store} />
        </div>
      )}
    </div>
  );
}
