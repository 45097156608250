import axios from "axios";

export const getAll = async () => {
  const { data } = await axios.get("/api/stores");
  return data;
};

export const storeSearch = async (searchTerm) => {
  const { data } = await axios.get("/api/stores/search/store/" + searchTerm);
  return data;
};

export const getById = async (storeId) => {
  const { data } = await axios.get("/api/stores/" + storeId);
  return data;
};
