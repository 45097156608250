import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { trackSendOrderById } from "../../services/sendService";
import NotFound from "../../components/NotFound/NotFound";
import DateTime from "../../components/DateTime/DateTime";

import "./SendOrderTrackPage.scss";

export default function SendOrderTrackPage() {
  const { sendOrderId } = useParams();
  const [sendOrder, setSendOrder] = useState();

  const zero = 0;

  useEffect(() => {
    sendOrderId &&
      trackSendOrderById(sendOrderId).then((sendOrder) => {
        setSendOrder(sendOrder);
      });
  }, []);

  if (!sendOrderId)
    return (
      <NotFound message="Send order not found" linkText="Go to home page" />
    );
  return (
    sendOrder && (
      <div className="SendOrderTrackPage">
        <div>
          <div className="SendOrderTrackPage-id">Order #{sendOrder.id}</div>
          <div className="SendOrderTrackPage-details">
            <strong>Date:</strong>
            <DateTime date={sendOrder.createdAt} />
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Status:</strong>
            <div>
              <span>{sendOrder.status}</span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Sender's Name:</strong>
            <div className="OrderTrackPage-details-name">
              <span>{sendOrder.senderName}</span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Sender's Phone Number:</strong>
            <div>
              <span>{zero + sendOrder.senderPhone.toString()}</span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Pickup Address:</strong>
            <div>
              <span className="SendOrderTrackPage-address">
                {sendOrder.senderAddress}
              </span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Receiver's Name:</strong>
            <div>
              <span>{sendOrder.receiverName}</span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Receiver's Phone Number:</strong>
            <div>
              <span>{zero + sendOrder.receiverPhone.toString()}</span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Delivery Address:</strong>
            <div>
              <span className="SendOrderTrackPage-address">
                {sendOrder.deliveryAddress}
              </span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Item:</strong>
            <div>
              <span className="SendOrderTrackPage-address">
                {sendOrder.itemInfo}
              </span>
            </div>
          </div>
          <div className="SendOrderTrackPage-details">
            <strong>Extra Information:</strong>
            <div>
              <span className="SendOrderTrackPage-address">
                {sendOrder.extraInfo}
              </span>
            </div>
          </div>
          {sendOrder.paymentId && (
            <div className="SendOrderTrackPage-details">
              <strong>Payment ID: </strong>
              {sendOrder.paymentId}
            </div>
          )}
        </div>

        {/* {sendOrder.status === "NEW" && (
          <div>
            <Link
              to="/sendPayment"
              className="SendOrderTrackPage-button Button"
            >
              Go to payment
            </Link>
          </div>
        )} */}
      </div>
    )
  );
}
