import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFoodById } from "../../services/foodService";
import { useCart } from "../../hooks/useCart";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";
import Price from "../../components/Price/Price";

import "./FoodPage.scss";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";
import { getById } from "../../services/storeService";
import GoBack from "../../components/GoBack/GoBack";

export default function FoodPage() {
  const [food, setFood] = useState({});
  const [store, setStore] = useState({});
  const { id, foodId } = useParams();
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const handleAddToCart = () => {
    addToCart(food);

    // navigate("/cart");
  };

  const DesktopView = () => {
    return (
      !isMobile && (
        <div className="FoodPage">
          <div>
            <GoBack title={store.name} />
          </div>
          {food && (
            <div className="FoodPage-content">
              <div className="FoodPage-imageContainer">
                <img
                  src={`${food.imageUrl}`}
                  alt={food.name}
                  className="FoodPage-imageContainer-image"
                />
              </div>
              <div className="FoodPage-content-right">
                <div className="FoodPage-content-right-text">
                  <h3>{food.name}</h3>
                  <div className="FoodPage-content-right-description">
                    {food.description}
                  </div>
                  <div>
                    <Price price={food.price} />
                  </div>
                </div>
                <button onClick={handleAddToCart} className="AddToCart-button">
                  Add to Cart
                </button>
              </div>
            </div>
          )}
        </div>
      )
    );
  };
  const MobileView = () => {
    return (
      isMobile && (
        <div className="FoodPage">
          <div className="FoodPage-goBack">
            <GoBack title={store.name} />
          </div>
          {food && (
            <div className="FoodPage-content">
              <div className="FoodPage-imageContainer">
                <img
                  src={`${food.imageUrl}`}
                  alt={food.name}
                  className="FoodPage-imageContainer-image"
                />
              </div>
              <div className="FoodPage-content-down">
                <h3>{food.name}</h3>
                <div className="FoodPage-content-down2">
                  <div className="FoodPage-content-down2-description">
                    {food.description}
                  </div>
                  <div>
                    <Price price={food.price} />
                  </div>
                  <button
                    onClick={handleAddToCart}
                    className="AddToCart-button"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )
    );
  };

  useEffect(() => {
    getFoodById(id, foodId).then(setFood);
    getById(id).then(setStore);
  }, [id, foodId]);

  return (
    <>
      <div>
        {/* {food && (
          <div>
            <div>{food.name}</div>
            <button onClick={handleAddToCart}>Add to Cart</button>
          </div>
        )} */}
        <ReactHelmet
          title={`${food.name} | Deliepoint`}
          description={`${food.description} | Deliepoint`}
          // ogurl={`https://deliepoint.com/store/${food.parentId}/food/${food.id}`}
          // ogtitle={`${food.name} | Deliepoint`}
          // ogdescription={`Looking for ${food.description}? Deliepoint has you covered! Browse our extensive food menu, order food online, and enjoy fast and reliable delivery right to your door. Say goodbye to long queues and waiting time with Deliepoint! `}
          // ogtype="website"
          // ogimagealt={food.name}
          // ogsitename="Delipoint"
          // oglocale="en_NG"
        />
        {DesktopView()}
        {MobileView()}
      </div>
    </>
  );
}
