export const DELIVERY_FEE = ({ order }) => {
  const initialDeliveryFee = 400;
  const initialTotalAmount = order.totalPrice + initialDeliveryFee;
  const paystackPercent = (initialTotalAmount / 100) * 1.6;
  const finalDeliveryFee = Math.ceil(initialDeliveryFee + paystackPercent);

  if (order.totalPrice >= 2000) {
    return finalDeliveryFee + 100;
  }

  return finalDeliveryFee;
};

export const SEND_DELIVERY_FEE = 1500;
