import React from "react";
import Title from "../Title/Title";
import { useNavigate } from "react-router-dom";

import "./GoBack.scss";

export default function GoBack({ title, home }) {
  const navigate = useNavigate();
  if (home) {
    return (
      <div className="GoBack" onClick={() => navigate("/")}>
        <span>←</span>
        <Title title={title} />
      </div>
    );
  }
  return (
    <div className="GoBack" onClick={() => navigate(-1)}>
      <span>←</span>
      <Title title={title} />
    </div>
  );
}
