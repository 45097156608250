import React from "react";
import { useCart } from "../../hooks/useCart";
import Title from "../../components/Title/Title";
import { Link } from "react-router-dom";
import Price from "../../components/Price/Price";
import NotFound from "../../components/NotFound/NotFound";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";

import "./CartPage.scss";
import TrashIcon from "../../components/TrashIcon/TrashIcon";

export default function CartPage() {
  const { cart, removeFromCart, changeQuantity } = useCart();

  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const DesktopView = () => {
    return (
      !isMobile && (
        <div className="CartPage">
          <Title title="Cart" />

          {cart.items.length === 0 ? (
            <NotFound message="Your cart is empty. Add items to get started" />
          ) : (
            <div>
              <ul>
                {cart.items.map((item) => (
                  <li key={item.food.id} className="CartPage-items">
                    <div className="CartPage-items-imageContainer">
                      <img
                        src={`${item.food.imageUrl}`}
                        alt={item.food.name}
                        className="CartPage-items-image"
                      />
                    </div>
                    <div>{item.food.name}</div>
                    <div>
                      <select
                        value={item.quantity}
                        onChange={(e) =>
                          changeQuantity(item, Number(e.target.value))
                        }
                        className="CartPage-quantitySelector"
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                      </select>
                    </div>

                    <div>
                      <Price price={item.price} />
                    </div>

                    <div>
                      <div onClick={() => removeFromCart(item.food.id)}>
                        <TrashIcon />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="CartPage-checkoutButton">
                {/* <div>{cart.totalCount}</div> */}

                <Link to="/checkout" className="CartPage-button Button">
                  Checkout (
                  <span>
                    <Price price={cart.totalPrice} />
                  </span>
                  )
                </Link>
              </div>
            </div>
          )}
        </div>
      )
    );
  };

  const MobileView = () => {
    return (
      isMobile && (
        <div className="CartPage">
          <Title title="Cart" />

          {cart.items.length === 0 ? (
            <NotFound message="Your cart is empty. Add items to get started" />
          ) : (
            <div>
              <ul>
                {cart.items.map((item) => (
                  <li key={item.food.id} className="CartPage-items">
                    <div className="CartPage-items-imageContainer">
                      <img
                        src={`${item.food.imageUrl}`}
                        alt={item.food.name}
                        className="CartPage-items-image"
                      />
                    </div>
                    <div className="CartPage-items-right">
                      <div className="CartPage-items-rightTop">
                        <div className="CartPage-items-name">
                          {item.food.name}
                        </div>
                        <div>
                          <div onClick={() => removeFromCart(item.food.id)}>
                            <TrashIcon />
                          </div>
                        </div>
                      </div>
                      <div className="CartPage-items-leftBottom">
                        <div>
                          <Price price={item.price} />
                        </div>
                        <div>
                          <select
                            value={item.quantity}
                            onChange={(e) =>
                              changeQuantity(item, Number(e.target.value))
                            }
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="CartPage-checkoutButton">
                {/* <div>{cart.totalCount}</div> */}
                {/* <div>
                  <Price price={cart.totalPrice} />
                </div> */}

                <Link to="/checkout" className="CartPage-button Button">
                  Checkout (
                  <span>
                    <Price price={cart.totalPrice} />
                  </span>
                  ){" "}
                </Link>
              </div>
            </div>
          )}
        </div>
      )
    );
  };
  return (
    <div>
      {DesktopView()}
      {MobileView()}
    </div>
  );
}
