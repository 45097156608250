import React, { useEffect, useReducer } from "react";
import { getAll, storeSearch } from "../../services/storeService";
import Thumbnails from "../../components/Thumbnails/Thumbnails";
import { useParams, useLocation } from "react-router-dom";
import NotFound from "../../components/NotFound/NotFound";

import "./HomePage.scss";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";
import Packages from "../../components/Packages/Packages";
import SendIcon from "../../components/SendIcon/SendIcon";
import ReceiveIcon from "../../components/ReceiveIcon/ReceiveIcon";

const initialState = { stores: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case "STORES_LOADED":
      return { ...state, stores: action.payload };
    default:
      return state;
  }
};

const allRestaurants = () => {
  return <h4 className="HomePage-allRestaurant">ALL RESTAURANTS</h4>;
};

export default function HomePage() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { stores } = state;
  const { searchTerm } = useParams();
  const location = useLocation();
  const sendIcon = <SendIcon />;
  const receiveIcon = <ReceiveIcon />;

  useEffect(() => {
    const loadStores = searchTerm ? storeSearch(searchTerm) : getAll();

    loadStores.then((stores) =>
      dispatch({ type: "STORES_LOADED", payload: stores })
    );
  }, [searchTerm]);
  return (
    <div className="HomePage">
      <ReactHelmet
        title="Deliepoint - You order, we deliver, in minutes!"
        description="Deliepoint is the food delivery site that will get you anything you want to your doorstep. Order from your favourite stores online and we'll deliver it to you in minutes!"
      />
      <div className="HomePage-packages">
        <Packages
          text="Send package"
          link="/send"
          icon={sendIcon}
          arrow="→"
          className="SendPackageIcon"
        />
        <Packages
          text="Receive package"
          link="/receive"
          icon={receiveIcon}
          arrow="←"
          className="ReceivePackageIcon"
        />
      </div>
      {stores.length === 0 && location.pathname !== "/" ? (
        <NotFound linkText="Reset Search" />
      ) : (
        allRestaurants()
      )}
      <Thumbnails stores={stores} />
    </div>
  );
}
