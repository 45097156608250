import React, { useState, useEffect } from "react";
import { getNewSendOrderForCurrentUser } from "../../services/sendService";
import Title from "../../components/Title/Title";
import SendButtonPaystack from "../../components/SendButtonPaystack/SendButtonPaystack";

import "./SendPaymentPage.scss";

export default function SendPaymentPage() {
  const [sendOrder, setSendOrder] = useState();

  useEffect(() => {
    getNewSendOrderForCurrentUser().then((data) => setSendOrder(data));
  }, []);

  if (!sendOrder) return;

  return (
    <>
      <div className="SendPaymentPage">
        <div className="SendPaymentPage-content">
          <div>
            <h1 className="SendPage-title">Sender's information</h1>
            <div className="SendPaymentPage-contact">
              <p>Sender's Name:</p>
              <div className="PaymentPage-contact-value">
                <span>{sendOrder.senderFirstname}</span>
                <span>{sendOrder.senderLastname}</span>
              </div>
            </div>
            <div className="SendPaymentPage-contact">
              <p>Sender's Phone Number:</p>
              <div className="PaymentPage-contact-value">
                <span>{sendOrder.senderPhone}</span>
              </div>
            </div>
            <div className="SendPaymentPage-contact">
              <p>Pickup Address:</p>
              <div className="PaymentPage-contact-value SendPaymentPage-address">
                <span>{sendOrder.senderAddress}</span>
              </div>
            </div>
          </div>
          <div>
            <h1 className="SendPage-title">Receiver's information</h1>
            <div className="SendPaymentPage-contact">
              <p>Receiver's Name:</p>
              <div className="PaymentPage-contact-value">
                <span>{sendOrder.receiverName}</span>
              </div>
            </div>
            <div className="SendPaymentPage-contact">
              <p>Receiver's Phone Number:</p>
              <div className="PaymentPage-contact-value">
                <span>{sendOrder.receiverPhone}</span>
              </div>
            </div>
            <div className="SendPaymentPage-contact">
              <p>Delivery Address:</p>
              <div className="PaymentPage-contact-value  SendPaymentPage-address">
                <span>{sendOrder.deliveryAddress}</span>
              </div>
            </div>
          </div>
          <div className="SendPaymentPage-buttonContainer">
            <SendButtonPaystack sendOrder={sendOrder} />
          </div>
        </div>
      </div>
    </>
  );
}
