import axios from "axios";

export const getUser = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

export const login = async (email, password) => {
  const { data } = await axios.post("api/users/login", { email, password });
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

export const register = async (registerData) => {
  const { data } = await axios.post("api/users/register", registerData);
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

export const verifyEmail = async (email) => {
  const { data } = await axios.post("api/users/forgotPassword", { email });
  return data;
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const updateProfile = async (user) => {
  const { data } = await axios.put("/api/users/updateProfile", user);
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

export const updateBalance = async (balance) => {
  const { data } = await axios.put("/api/users/updateBalance", { balance });
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

export const useBalance = async (balance) => {
  const { data } = await axios.put("/api/users/useBalance", { balance });
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

export const changePassword = async (passwords) => {
  await axios.put("/api/users/changePassword", passwords);
};

export const resetPassword = async (passwords, id, passwordResetToken) => {
  await axios.put(
    `/api/users/resetPassword/${id}/${passwordResetToken}`,
    passwords
  );
};
