import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";

export default function RegisterPage() {
  const auth = useAuth();
  const { user } = auth;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  useEffect(() => {
    if (!user) return;
    returnUrl ? navigate(returnUrl) : navigate("/");
  }, [user, navigate, returnUrl]);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    await auth.register(data);
  };

  return (
    <div className="RegisterPage">
      <ReactHelmet
        title="Create a Deliepoint account"
        description="Deliepoint is the food delivery site that will get you anything you want to your doorstep. Order from your favourite stores online and we'll deliver it to you in minutes!"
      />
      <div className="RegisterPage-content">
        <Title title="Register" className="RegisterPage-title" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            className="RegisterPage-input"
            type="text"
            placeholder="Firstname"
            {...register("firstname", {
              required: true,
              minLength: 1,
            })}
            error={errors.firstname}
          />
          <Input
            className="RegisterPage-input"
            type="text"
            placeholder="Lastname"
            {...register("lastname", {
              required: true,
              minLength: 1,
            })}
            error={errors.lastname}
          />
          <Input
            className="RegisterPage-input"
            type="email"
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: "Email is not valid",
              },
            })}
            error={errors.email}
          />
          <Input
            className="RegisterPage-input"
            type="tel"
            placeholder="Phone Number"
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9]{11}$/,
                message: "Enter a valid phone number",
              },
            })}
            error={errors.phone}
          />
          <Input
            className="RegisterPage-input"
            type="text"
            placeholder="Address"
            {...register("address", {
              required: true,
              minLength: 5,
            })}
            error={errors.address}
          />
          <Input
            className="RegisterPage-input"
            type="password"
            placeholder="Password"
            {...register("password", {
              required: true,
              minLength: 5,
            })}
            error={errors.password}
          />
          <Input
            className="RegisterPage-input"
            type="password"
            placeholder="Confirm Password"
            {...register("confirmPassword", {
              required: true,
              validate: (value) =>
                value !== getValues("password")
                  ? "Passwords do no match"
                  : true,
            })}
            error={errors.confirmPassword}
          />
          <Input
            className="RegisterPage-input"
            type="text"
            placeholder="Referral Code (if any)"
            {...register("referrer", {
              required: false,
              minLength: 1,
            })}
            error={errors.referrer}
          />

          <Button
            type="submit"
            text="Register"
            className="RegisterPage-button"
          />

          <div className="RegisterPage-signupRedirect">
            Already a user? &nbsp;
            <Link to={`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`}>
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
