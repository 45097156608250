import { Link } from "react-router-dom";
import Title from "../../components/Title/Title";
import { useAuth } from "../../hooks/useAuth";

import "./SendOrderConfirmation.scss";

export default function SendOrderConfirmation() {
  const { user } = useAuth();
  return (
    <div className="SendOrderDelivery">
      <Title title="Delivery order received! 🚀" />
      <div>
        Hey {user.firstname}, your delivery order has been received. You will be
        contacted now for the service fee which is based on the distance. Thank
        you for choosing deliepoint!
      </div>
      <div className="Button SendOrderDelivery-seeAllOrders">
        <Link to="/sendOrders" className="Button">
          See all delivery order(s)
        </Link>
      </div>
    </div>
  );
}
