import React, { useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import { getAll, getAllStatus } from "../../services/sendService";
import Title from "../../components/Title/Title";
import DateTime from "../../components/DateTime/DateTime";
import Price from "../../components/Price/Price";
import NotFound from "../../components/NotFound/NotFound";

import "./SendOrdersPage.scss";

const initialState = {};
const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ALL_STATUS_FETCHED":
      return { ...state, allStatus: payload };
    case "ORDERS_FETCHED":
      return { ...state, sendOrders: payload };
    default:
      return state;
  }
};
export default function SendOrdersPage() {
  const [{ allStatus, sendOrders }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { filter } = useParams();

  const zero = 0;

  useEffect(() => {
    getAllStatus().then((status) => {
      dispatch({ type: "ALL_STATUS_FETCHED", payload: status });
    });
    getAll(filter).then((sendOrders) => {
      dispatch({ type: "ORDERS_FETCHED", payload: sendOrders });
    });
  }, [filter]);

  return (
    <div className="SendOrdersPage">
      <Title title="Package delivery history" />

      {allStatus && (
        <div className="SendOrdersPage-selectTag">
          <Link to="/sendOrders" className={!filter ? "selected" : ""}>
            All
          </Link>
          {allStatus.map((state) => (
            <Link
              key={state}
              className={state == filter ? "selected" : ""}
              to={`/sendOrders/${state}`}
            >
              {state}
            </Link>
          ))}
        </div>
      )}

      {sendOrders?.length === 0 && (
        <NotFound
          linkRoute={filter ? "/sendOrders" : "/"}
          linkText={filter ? "Show All" : "Go To Home Page"}
        />
      )}

      {sendOrders &&
        sendOrders.map((sendOrder) => (
          <div key={sendOrder.id} className="SendOrdersPage-item">
            <div className="SendOrdersPage-item-top">
              <span>#{sendOrder.id}</span>
              <span>
                <DateTime date={sendOrder.createdAt} />
              </span>
              <span>{sendOrder.status}</span>
            </div>
            <div className="SendOrdersPage-info">
              <div className=" SendOrdersPage-info-sender">
                <div className=" SendOrdersPage-info-direction">
                  <span>From :</span>
                </div>
                <div className=" SendOrdersPage-info-sender-details">
                  <span>{sendOrder.senderName}</span>
                  <span>{zero + sendOrder.senderPhone.toString()}</span>
                  <span className=" SendOrdersPage-address">
                    {sendOrder.senderAddress}
                  </span>
                </div>
              </div>
              <div className=" SendOrdersPage-info-seperator"></div>
              <div className="SendOrdersPage-info-receiver">
                <div className=" SendOrdersPage-info-direction">
                  <span>To :</span>
                </div>

                <div className=" SendOrdersPage-info-receiver-details">
                  <span>{sendOrder.receiverName}</span>
                  <span>{zero + sendOrder.receiverPhone.toString()}</span>
                  <span className=" SendOrdersPage-address">
                    {sendOrder.deliveryAddress}
                  </span>
                </div>
              </div>
            </div>
            <div className="SendOrdersPage-item-bottom">
              <div>
                <Link
                  to={`/sendTrack/${sendOrder.id}`}
                  className="SendOrdersPage-showOrder"
                >
                  Show Order
                </Link>
              </div>
              {/* <div>
                <span>
                  <Price price={sendOrder.deliveryFee} />
                </span>
              </div> */}
            </div>
          </div>
        ))}
    </div>
  );
}
