import React, { useState, useEffect } from "react";
import { getNewOrderForCurrentUser } from "../../services/orderService";
import Title from "../../components/Title/Title";
import OrderItemsList from "../../components/OrderItemsList/OrderItemsList";
import ButtonPaystack from "../../components/ButtonPaystack/ButtonPaystack";

import "./PaymentPage.scss";
import BalancePayButton from "../../components/BalancePayButton/BalancePayButton";

export default function PaymentPage() {
  const [order, setOrder] = useState();

  useEffect(() => {
    getNewOrderForCurrentUser().then((data) => setOrder(data));
  }, []);

  if (!order) return;

  return (
    <>
      <div className="PaymentPage">
        <div>
          <Title title="Order" />
          <div>
            <div className="PaymentPage-contact">
              <div>Name:</div>
              <div className="PaymentPage-contact-value PaymentPage-contact-name">
                <span>{order.firstname}</span>
                <span>{order.lastname}</span>
              </div>
            </div>
            <div className="PaymentPage-contact">
              <p>Delivery address:</p>
              <div className="PaymentPage-contact-value">
                <span>{order.address}</span>
              </div>
            </div>
          </div>
          <OrderItemsList order={order} />
        </div>
        <div className="PaymentPage-buttons">
          <div>
            <ButtonPaystack order={order} />
          </div>
          <div>
            <BalancePayButton order={order} />
          </div>
        </div>
      </div>
    </>
  );
}
