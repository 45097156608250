import React from "react";

import "./ContactUsPage.scss";
import Title from "../../components/Title/Title";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";

export default function ContactUsPage() {
  return (
    <div className="ContactUsPage">
      <ReactHelmet
        title="Contact Us - Deliepoint"
        description="We'd love to hear from you. Send us a message. We reply in minutes!"
      />
      <div className="ContactUsPage-content">
        <Title title="Contact Us" className="ContactUsPage-title" />
        <div className="ContactUsPage-details">
          <div>
            Email:
            <span>
              {" "}
              <a
                href="mailto:hello.deliepoint@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="ContactUsPage-links"
              >
                hello.deliepoint@gmail.com
              </a>
            </span>
          </div>
          <div>
            WhatsApp:
            <span>
              {" "}
              <a
                className="ContactUsPage-links"
                href="https://wa.link/9sy70j"
                target="_blank"
                rel="noreferrer"
              >
                0803 358 1100
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
