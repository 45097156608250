import React, { useState } from "react";
import { useCart } from "../../hooks/useCart";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createOrder } from "../../services/orderService";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import OrderItemsList from "../../components/OrderItemsList/OrderItemsList";
import Map from "../../components/Map/Map";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";
import { DELIVERY_FEE } from "../../constants/deliveryFee";

import "./CheckoutPage.scss";

export default function CheckoutPage() {
  const { cart } = useCart();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [order, setOrder] = useState({ ...cart });

  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const zero = 0;

  const submit = async (data) => {
    // if (!order.addressLatLng) {
    //   toast.warning("Please select your location on the map");
    //   return;
    // }

    await createOrder({
      ...order,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      address: data.address,
      deliveryFee: DELIVERY_FEE({ order }),
    });
    navigate("/payment");
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="CheckoutPage-form">
      <div>
        <Title title="Order" />
        <div>
          <Input
            defaultValue={user.firstname}
            label="Firstame"
            {...register("firstname")}
            error={errors.firstname}
            className="CheckoutPage-input"
          />
          <Input
            defaultValue={user.lastname}
            label="Lastname"
            {...register("lastname")}
            error={errors.lastname}
            className="CheckoutPage-input"
          />
          <Input
            defaultValue={zero + user.phone.toString()}
            label="Phone number"
            {...register("phone")}
            error={errors.phone}
            className="CheckoutPage-input"
          />
          <Input
            defaultValue={user.address}
            label="Delivery address"
            {...register("address")}
            error={errors.address}
            className="CheckoutPage-input"
          />
        </div>
      </div>
      <div>
        <OrderItemsList order={order} />

        <div>
          <div>
            <Button
              type="submit"
              text="Confirm Order"
              className="ConfirmOrder-button"
            />
          </div>
        </div>
      </div>
    </form>
  );
}
