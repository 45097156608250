import axios from "axios";

export const createSendOrder = async (sendOrder) => {
  try {
    const { data } = await axios.post("/api/sendOrders/create", sendOrder);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getNewSendOrderForCurrentUser = async () => {
  try {
    const { data } = await axios.get(
      "/api/sendOrders/newSendOrderForCurrentUser"
    );
    return data;
  } catch (error) {
    console.log("error is" + error);
  }
};

export const sendPay = async () => {
  try {
    const { data } = await axios.put("/api/sendOrders/sendPay");
    return data;
  } catch (error) {}
};

export const trackSendOrderById = async (sendOrderId) => {
  const { data } = await axios.get("/api/sendOrders/sendTrack/" + sendOrderId);
  return data;
};

export const getAll = async (state) => {
  const { data } = await axios.get(`/api/sendOrders/${state ?? ""}`);
  return data;
};

export const getAllStatus = async () => {
  const { data } = await axios.get(`/api/sendOrders/allstatus`);
  return data;
};
