import React from "react";

import "../SendIcon/SendIcon.scss";

export default function ReceiveIcon() {
  return (
    <svg
      viewBox="0 0 1024 1024"
      className="PackagesIcon"
      class="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M515.2 32L364 112.8l-32.8 36.8-72 19.2L80 264v469.6L515.2 992 944 733.6V264z"
        fill="#D6AB7F"
      />
      <path
        d="M259.2 168.8L679.2 408v96l107.2-58.4V348.8L364 112.8z"
        fill="#FFFFFF"
      />
      <path
        d="M515.2 1000c-1.6 0-3.2 0-4-0.8L76 740.8c-2.4-1.6-4-4.8-4-7.2V264c0-3.2 1.6-5.6 4-7.2 2.4-1.6 5.6-1.6 8 0l435.2 232c4 2.4 5.6 7.2 3.2 11.2-2.4 4-7.2 5.6-11.2 4L88 277.6v451.2l427.2 253.6L936 728.8V264c0-4.8 3.2-8 8-8s8 3.2 8 8v469.6c0 3.2-1.6 5.6-4 7.2l-428.8 258.4c-0.8 0.8-2.4 0.8-4 0.8z"
        fill="#6A576D"
      />
      <path d="M515.2 992L944 733.6V264L515.2 496.8z" fill="#121519" />
      <path d="M259.2 168.8L360 115.2z" fill="#FFFFFF" />
      <path
        d="M515.2 1000c-1.6 0-3.2 0-4-0.8L76 740.8c-2.4-1.6-4-4.8-4-7.2V264c0-3.2 1.6-5.6 4-7.2l179.2-96c4-2.4 8.8-0.8 11.2 3.2 2.4 4 0.8 8.8-3.2 11.2L88 268.8v460l427.2 253.6L936 728.8V268.8L515.2 40.8 367.2 120c-4 2.4-8.8 0.8-11.2-3.2-2.4-4-0.8-8.8 3.2-11.2l152-80.8c2.4-1.6 5.6-1.6 7.2 0l428.8 232c2.4 1.6 4 4 4 7.2v469.6c0 3.2-1.6 5.6-4 7.2l-428.8 258.4c0 0.8-1.6 0.8-3.2 0.8z"
        fill="#6A576D"
      />
      <path
        d="M679.2 512c-1.6 0-3.2 0-4-0.8-2.4-1.6-4-4-4-7.2V412l-416-236.8c-2.4-1.6-4-4-4-7.2s1.6-5.6 4-7.2l104-55.2c2.4-1.6 5.6-1.6 8 0l423.2 236c2.4 1.6 4 4 4 7.2v96.8c0 3.2-1.6 5.6-4 7.2l-107.2 58.4c-0.8 0.8-2.4 0.8-4 0.8zM276 168.8l407.2 232c2.4 1.6 4 4 4 7.2v83.2l91.2-49.6v-88L364 122.4l-88 46.4z"
        fill="#6A576D"
      />
      <path
        d="M515.2 1000c-1.6 0-3.2 0-4-0.8L76 740.8c-2.4-1.6-4-4.8-4-7.2V264c0-3.2 1.6-5.6 4-7.2 2.4-1.6 5.6-1.6 8 0l431.2 230.4L672 402.4c4-2.4 8.8-0.8 11.2 3.2 2.4 4 0.8 8.8-3.2 11.2L519.2 504c-2.4 1.6-5.6 1.6-7.2 0L88 277.6v451.2l427.2 253.6L936 728.8V277.6l-144.8 78.4c-4 2.4-8.8 0.8-11.2-3.2s-0.8-8.8 3.2-11.2l156.8-84.8c2.4-1.6 5.6-1.6 8 0 2.4 1.6 4 4 4 7.2v469.6c0 3.2-1.6 5.6-4 7.2l-428.8 258.4c-0.8 0.8-2.4 0.8-4 0.8zM259.2 176.8c-3.2 0-5.6-1.6-7.2-4-0.8-0.8-0.8-2.4-0.8-4 0-3.2 1.6-5.6 4-7.2l100-53.6c4-2.4 8.8-0.8 11.2 3.2 0.8 0.8 0.8 2.4 0.8 4 0 3.2-1.6 5.6-4 7.2L263.2 176c-0.8 0-2.4 0.8-4 0.8z"
        fill="#6A576D"
      />
      <path
        d="M515.2 1000c-4.8 0-8-3.2-8-8V496.8c0-4.8 3.2-8 8-8s8 3.2 8 8V992c0 4.8-3.2 8-8 8z"
        fill="#6A576D"
      />
    </svg>
  );
}
