import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import StorePage from "./pages/Store/StorePage";
import FoodPage from "./pages/Food/FoodPage";
import CartPage from "./pages/Cart/CartPage";
import LoginPage from "./pages/Login/LoginPage";
import RegisterPage from "./pages/Register/RegisterPage";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import CheckoutPage from "./pages/Checkout/CheckoutPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import OrderTrackPage from "./pages/OrderTrack/OrderTrackPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import OrdersPage from "./pages/Orders/OrdersPage";
import ContactUsPage from "./pages/ContactUs/ContactUsPage";
import SendPage from "./pages/Send/SendPage";
import SendPaymentPage from "./pages/SendPayment/SendPaymentPage";
import SendOrdersPage from "./pages/SendOrders/SendOrdersPage";
import SendOrderTrackPage from "./pages/SendOrderTrack/SendOrderTrackPage";
import TopUpPage from "./pages/TopUp/TopUpPage";
import EditProfile from "./components/EditProfile/EditProfile";
import SendOrderConfirmation from "./pages/SendOrderConfirmation/SendOrderConfirmation";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";
import ReceivePage from "./pages/Receive/ReceivePage";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search/store/:searchTerm" element={<HomePage />} />
      <Route path="/store/:id" element={<StorePage />} />
      <Route path="/store/:id/foodTag/:foodTag" element={<StorePage />} />
      <Route path="/store/:id/food/:foodId" element={<FoodPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
      <Route
        path="/resetPassword/:id/:passwordResetToken"
        element={<ResetPasswordPage />}
      />
      <Route
        path="/send"
        element={
          <AuthRoute>
            <SendPage />
          </AuthRoute>
        }
      />
      <Route
        path="/receive"
        element={
          <AuthRoute>
            <ReceivePage />
          </AuthRoute>
        }
      />
      <Route
        path="/sendPayment"
        element={
          <AuthRoute>
            <SendPaymentPage />
          </AuthRoute>
        }
      />
      <Route
        path="/checkout"
        element={
          <AuthRoute>
            <CheckoutPage />
          </AuthRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <AuthRoute>
            <PaymentPage />
          </AuthRoute>
        }
      />
      <Route
        path="/track/:orderId"
        element={
          <AuthRoute>
            <OrderTrackPage />
          </AuthRoute>
        }
      />
      <Route
        path="/sendTrack/:sendOrderId"
        element={
          <AuthRoute>
            <SendOrderTrackPage />
          </AuthRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthRoute>
            <ProfilePage />
          </AuthRoute>
        }
      />
      <Route
        path="/orders/:filter?"
        element={
          <AuthRoute>
            <OrdersPage />
          </AuthRoute>
        }
      />
      <Route
        path="/sendOrders/:filter?"
        element={
          <AuthRoute>
            <SendOrdersPage />
          </AuthRoute>
        }
      />
      <Route
        path="/topup"
        element={
          <AuthRoute>
            <TopUpPage />
          </AuthRoute>
        }
      />
      <Route
        path="/editProfile"
        element={
          <AuthRoute>
            <EditProfile />
          </AuthRoute>
        }
      />
      <Route
        path="/SendOrderConfirmation"
        element={
          <AuthRoute>
            <SendOrderConfirmation />
          </AuthRoute>
        }
      />
    </Routes>
  );
}
