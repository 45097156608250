import React from "react";
import InputContainer from "../InputContainer/InputContainer";

import "./Input.scss";

function Input(
  {
    label,
    placeholder,
    type,
    defaultValue,
    onChange,
    onBlur,
    name,
    error,
    className,
    value,
  },
  ref
) {
  const getErrorMessage = () => {
    if (!error) return;
    if (error.message) return error.message;
    //defaults
    switch (error.type) {
      case "required":
        return "This field is required";
      case "minLength":
        return "Field is too short";
      default:
        return "*";
    }
  };

  if (type === "textarea") {
    return (
      <InputContainer label={label}>
        <textarea
          defaultValue={defaultValue}
          className={className}
          type={type}
          placeholder={placeholder}
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          rows="5"
        />
        {error && <div className="Input-error">{getErrorMessage()}</div>}
      </InputContainer>
    );
  }

  return (
    <InputContainer label={label}>
      <input
        defaultValue={defaultValue}
        className={className}
        type={type}
        placeholder={placeholder}
        ref={ref}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {error && <div className="Input-error">{getErrorMessage()}</div>}
    </InputContainer>
  );
}

export default React.forwardRef(Input);
