import React from "react";
import { PaystackButton } from "react-paystack";
import { useAuth } from "../../hooks/useAuth";
import { pay } from "../../services/orderService";
import { useCart } from "../../hooks/useCart";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DELIVERY_FEE } from "../../constants/deliveryFee";

import "./ButtonPaystack.scss";

export default function ButtonPaystack({ order }) {
  const { clearCart } = useCart();
  const { user } = useAuth();
  const navigate = useNavigate();

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY;
  const amount = (order.totalPrice + DELIVERY_FEE({ order })) * 100;
  const email = user.email;
  const firstname = user.firstname;
  const lastname = user.lastname;
  const phone = user.phone;

  const onSuccess = async () => {
    try {
      const orderId = await pay();
      clearCart();
      toast.success("Payment successful", "Success");
      navigate("/track/" + orderId);
    } catch (error) {
      toast.error("Payment failed", "Error");
    }
  };

  const componentProps = {
    amount,
    email,
    publicKey,
    metadata: {
      firstname,
      lastname,
      phone,
    },
    text: "Pay with Paystack",
    onSuccess: () => onSuccess(),
  };
  return (
    <div>
      <PaystackButton {...componentProps} className="Paystack-button Button" />
    </div>
  );
}
