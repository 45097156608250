import React from "react";
import { Link } from "react-router-dom";

import "./FoodThumbnails.scss";
import Price from "../Price/Price";

export default function FoodThumbnails({ foods, store }) {
  return (
    <ul>
      {foods && (
        <div className="FoodThumbnails">
          {foods.map((food) => (
            <li key={food.id} className="FoodThumbnails-thumbnail">
              <Link
                to={`/store/${store.id}/food/${food.id}`}
                className="FoodThumbnails-thumbnail-link"
              >
                <div className="FoodThumbnails-thumbnail-link-left">
                  <div className="name">{food.name}</div>
                  <div className="FoodThumbnails-thumbnail-description">
                    {food.description}
                  </div>
                  <div>
                    <Price price={food.price} />
                  </div>
                </div>
                <div className="FoodThumbnails-thumbnail-link-imageContainer">
                  <img
                    src={`${food.imageUrl}`}
                    alt={food.name}
                    className="FoodThumbnails-thumbnail-link-imageContainer-image"
                  />
                </div>
              </Link>
            </li>
          ))}
        </div>
      )}
    </ul>
  );
}
