import React from "react";
import { PaystackButton } from "react-paystack";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { sendPay } from "../../services/sendService";
import { useNavigate } from "react-router-dom";

export default function SendButtonPaystack({ sendOrder }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY;
  const amount = sendOrder.deliveryFee * 100;
  const email = user.email;
  const firstname = user.firstname;
  const lastname = user.lastname;
  const phone = user.phone;

  const onSuccess = async () => {
    try {
      const sendOrderId = await sendPay();
      toast.success("Payment successful!", "Success");
      navigate("/sendTrack/" + sendOrderId);
    } catch (error) {
      toast.error("Payment failed", "Error");
    }
  };

  const componentProps = {
    amount,
    email,
    publicKey,
    metadata: {
      firstname,
      lastname,
      phone,
    },
    text: `Pay with Paystack (N${sendOrder.deliveryFee})`,
    onSuccess: () => onSuccess(),
  };
  return (
    <div>
      <PaystackButton
        {...componentProps}
        className="Paystack-button Button SendPaymentPage-button"
      />
    </div>
  );
}
