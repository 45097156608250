import React from "react";
import Input from "../../components/Input/Input";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { createSendOrder } from "../../services/sendService";
import { SEND_DELIVERY_FEE } from "../../constants/deliveryFee";
import emailjs from "@emailjs/browser";

import "./SendPage.scss";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";
import GoBack from "../../components/GoBack/GoBack";

export default function SendPage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const zero = 0;

  const userPhone = zero.toString() + user.phone.toString();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const emailjsServiceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailjsTemplateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const submit = async (data) => {
    await createSendOrder({
      senderName: data.senderName,
      //senderLastname: data.senderLastname,
      senderPhone: data.senderPhone,
      senderAddress: data.senderAddress,
      receiverName: data.receiverName,
      receiverPhone: data.receiverPhone,
      deliveryAddress: data.deliveryAddress,
      itemInfo: data.itemInfo,
      extraInfo: data.extraInfo,
      deliveryFee: SEND_DELIVERY_FEE,
    });

    const templateParams = {
      user_firstname: user.firstname,
      user_phone: user.phone,
      sender_name: data.senderName,
      sender_phone: data.senderPhone,
      sender_address: data.senderAddress,
      receiver_name: data.receiverName,
      receiver_phone: data.receiverPhone,
      delivery_address: data.deliveryAddress,
      item_info: data.itemInfo,
      extra_info: data.extraInfo,
      message: "Please check it out now!",
    };

    emailjs.send(
      emailjsServiceID,
      emailjsTemplateID,
      templateParams,
      emailjsPublicKey
    );

    //navigate("/sendPayment");
    navigate("/SendOrderConfirmation");
  };

  return (
    <div className="SendPage">
      <ReactHelmet
        title="Send Package - Deliepoint"
        description="Send and receive packages quickly and easily with deliepoint"
      />
      <div className="SendPage-content">
        <GoBack title="Send a package" home />
        {/* <div className="SendPage-content-account">
          <div className="SendPage-content-account-warning">
            Make payments for deliveries to this account only!
          </div>
          <div>
            <div>Bank: OPAY</div>
            <div>Account number: 7014277095</div>
            <div>Account name: DANIEL IHEANYICHUKWU</div>
          </div>
        </div> */}
        <h3 className="SendPage-title">Sender's Information</h3>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <div>
            <div>
              <Input
                className="SendPage-input"
                defaultValue={user.firstname + user.lastname}
                {...register("senderName", {
                  required: true,
                  minLength: 1,
                })}
                label="Name"
                error={errors.senderName}
              />
              {/* <Input
                className="SendPage-input"
                defaultValue={user.lastname}
                {...register("senderLastname", {
                  required: true,
                  minLength: 1,
                })}
                label="Sender's Lastname"
                error={errors.senderLastname}
              /> */}
              <Input
                className="SendPage-input"
                defaultValue={userPhone}
                {...register("senderPhone", {
                  required: true,
                  pattern: {
                    value: /^[0-9]{11}$/,
                    message: "Enter a valid phone number",
                  },
                })}
                label="Phone number"
                error={errors.senderPhone}
              />
              <Input
                className="SendPage-input"
                defaultValue={user.address}
                {...register("senderAddress", {
                  required: true,
                  minLength: 5,
                })}
                label="Pickup address"
                error={errors.senderAddress}
              />
            </div>
            <h3 className="SendPage-title">Receiver's Information</h3>
            <Input
              className="SendPage-input"
              {...register("receiverName", {
                required: true,
                minLength: 1,
              })}
              label="Name"
              error={errors.receiverName}
            />
            <Input
              className="SendPage-input"
              {...register("receiverPhone", {
                required: true,
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: "Enter a valid phone number",
                },
              })}
              label="Phone number"
              error={errors.receiverPhone}
            />
            <Input
              className="SendPage-input"
              {...register("deliveryAddress", {
                required: true,
                minLength: 5,
              })}
              label="Delivery address"
              error={errors.deliveryAddress}
            />

            <h3 className="SendPage-title">Item's Information</h3>
            <Input
              className="SendPage-input"
              {...register("itemInfo", {
                minLength: 1,
              })}
              label="What are we delivering for you? (Optional)"
              error={errors.itemInfo}
            />

            <h3 className="SendPage-title">Extra Information</h3>
            <Input
              className="SendPage-input"
              type="textarea"
              {...register("extraInfo", {
                minLength: 1,
              })}
              label="Any additional information for this delivery? (Optional)"
              error={errors.extraInfo}
            />
          </div>

          <div>
            <div>
              <Button
                type="submit"
                text="Send Package"
                className="SendPage-button"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
