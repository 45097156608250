import React, { useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { useCart } from "../../hooks/useCart";
import { useAuth } from "../../hooks/useAuth";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";
import { useLocation } from "react-router-dom";

import "./Header.scss";
import Search from "../Search/Search";
import AccountIcon from "../AccountIcon/AccountIcon";
import SearchIcon from "../SearchIcon/SearchIcon";
import CloseIcon from "../CloseIcon/CloseIcon";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";
import CartIcon from "../CartIcon/CartIcon";
import Logo from "../Logo/Logo";

export default function Header() {
  const { user, logout } = useAuth();

  const { cart } = useCart();

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    closeSearch();
  };

  const toggleSearchOpen = () => {
    setSearchOpen(!searchOpen);
    closeMenu();
  };

  const closeSearch = () => {
    setSearchOpen(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const location = useLocation();
  const currentPath = location.pathname;

  const UserDesktop = () => {
    return user && !isMobile ? (
      <div className="Header-userDesktop">
        <Search closeSearch={closeSearch} />
        <ul className="Header-leftDetails">
          <li className="Header-detailsItem">
            <Link to="/cart">
              Cart
              {cart.totalCount > 0 && (
                <span className="Header-cartCount">{cart.totalCount}</span>
              )}
            </Link>
          </li>
          {/* <li className="Header-detailsItem">
            <Link to="/orders">Orders</Link>
          </li> */}
          <li className="Header-detailsItem">
            <Link to="/contact">Contact us</Link>
          </li>
          {/* <li className="Header-detailsItem">
            <Link to="/send">Send package</Link>
          </li> */}
          {/* <li className="Header-detailsItem">
            <Link to="/sendOrders">Send history</Link>
          </li> */}
        </ul>
        <ul className="Header-rightDetails">
          {/* <li className="Header-detailsItem">
            <Link to="/profile" className="Header-profile">
              <AccountIcon />
              <span>{user.firstname}</span>
            </Link>
          </li> */}
          {/* <li className="Header-detailsItem">
            <a onClick={logout}>Logout</a>
          </li> */}
          <li className="Header-detailsItem Header-dropdown">
            <div className="Header-dropButton">
              <AccountIcon /> <span>{user.firstname} ▾</span>
            </div>
            <ul className="Header-dropContent">
              <li className="Header-dropItem">
                <Link to="/profile">Profile</Link>
              </li>
              <li className="Header-dropItem">
                <Link to="/orders">Orders</Link>
              </li>
              <li className="Header-dropItem">
                <Link to="/sendOrders">Package delivery history</Link>
              </li>
              <li className="Header-dropItem">
                <a onClick={logout}>Logout</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    ) : null;
  };

  const GuestDesktop = () => {
    return !user && !isMobile ? (
      <div className="Header-guestDesktop">
        <Search closeSearch={closeSearch} />
        <ul className="Header-leftDetails">
          <li className="Header-detailsItem">
            <Link to="/cart">
              Cart
              {cart.totalCount > 0 && (
                <span className="Header-cartCount">{cart.totalCount}</span>
              )}
            </Link>
          </li>

          <li className="Header-detailsItem">
            <Link to="/contact">Contact us</Link>
          </li>

          {/* <li className="Header-detailsItem">
            <Link to="/send">Send package</Link>
          </li> */}
        </ul>
        <ul className="Header-rightDetails">
          <li className="Header-detailsItem Header-dropdown">
            <div className="Header-dropButton">Account ▾</div>
            <ul className="Header-dropContent">
              <li className="Header-dropItem">
                <Link to="/login">Login</Link>
              </li>
              <li className="Header-dropItem">
                <Link to="/orders">Orders</Link>
              </li>
              <li className="Header-dropItem">
                <Link to="/sendOrders">Package delivery history</Link>
              </li>
            </ul>
          </li>
          <li className="Header-detailsItem">
            <Link to="/register" className="Button">
              Sign Up
            </Link>
          </li>
        </ul>
      </div>
    ) : null;
  };

  const UserMobile = () => {
    return user && isMobile ? (
      <div>
        {!currentPath.includes("cart") && (
          <div className="Header-floatingCart">
            <Link to="/cart">
              <CartIcon />
              {cart.totalCount > 0 && (
                <span className="Header-cartCount">{cart.totalCount}</span>
              )}
            </Link>
          </div>
        )}
        <div className="Header-mobileIcons">
          <div onClick={toggleSearchOpen} className="Header-searchCancelIcon">
            {!searchOpen ? <SearchIcon /> : <CloseIcon />}
          </div>

          <div onClick={toggleMenuOpen}>
            {!menuOpen ? <HamburgerIcon /> : <CloseIcon />}
          </div>
        </div>
        {searchOpen && (
          <div className="Header-searchSection">
            <Search closeSearch={closeSearch} />
          </div>
        )}
        {menuOpen && (
          <div className="Header-userMobile">
            <ul>
              <li className="Header-userMobile-item">
                <Link to="/profile" onClick={closeMenu}>
                  <div className="Header-profile">
                    <AccountIcon />
                    <span>{user.firstname}</span>
                  </div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link to="/cart" onClick={closeMenu}>
                  <div>
                    Cart
                    {cart.totalCount > 0 && (
                      <span className="Header-cartCount">
                        {cart.totalCount}
                      </span>
                    )}
                  </div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link to="/orders" onClick={closeMenu}>
                  <div>Orders</div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link to="/contact" onClick={closeMenu}>
                  <div>Contact us</div>
                  <div>▸</div>
                </Link>
              </li>
              {/* <li className="Header-userMobile-item">
                <Link to="/send" onClick={closeMenu}>
                  <div>Send package</div>
                  <div>▸</div>
                </Link>
              </li> */}
              <li className="Header-userMobile-item">
                <Link to="/sendOrders" onClick={closeMenu}>
                  <div>Package delivery history</div>
                  <div>▸</div>
                </Link>
              </li>
              <li
                onClick={closeMenu}
                className="Header-userMobile-item Header-mobileLogout"
              >
                <a onClick={logout}>
                  {" "}
                  <div>Logout</div>
                  <div>▸</div>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  const GuestMobile = () => {
    return !user && isMobile ? (
      <div>
        {!currentPath.includes("cart") && (
          <div className="Header-floatingCart">
            <Link to="/cart">
              <CartIcon />
              {cart.totalCount > 0 && (
                <span className="Header-cartCount">{cart.totalCount}</span>
              )}
            </Link>
          </div>
        )}
        <div className="Header-mobileIcons">
          <div onClick={toggleSearchOpen} className="Header-searchCancelIcon">
            {!searchOpen ? <SearchIcon /> : <CloseIcon />}
          </div>
          <div onClick={toggleMenuOpen}>
            {!menuOpen ? <HamburgerIcon /> : <CloseIcon />}
          </div>
        </div>
        {searchOpen && (
          <div className="Header-searchSection">
            <Search closeSearch={closeSearch} />
          </div>
        )}
        {menuOpen && (
          <div className="Header-guestMobile">
            <ul>
              <li className="Header-guestMobile-item">
                <Link to="/cart" onClick={closeMenu}>
                  <div>
                    Cart
                    {cart.totalCount > 0 && (
                      <span className="Header-cartCount">
                        {cart.totalCount}
                      </span>
                    )}
                  </div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-guestMobile-item">
                <Link to="/orders" onClick={closeMenu}>
                  <div>Orders</div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-guestMobile-item">
                <Link to="/contact" onClick={closeMenu}>
                  <div>Contact us</div>
                  <div>▸</div>
                </Link>
              </li>
              {/* <li className="Header-guestMobile-item">
                <Link to="/send" onClick={closeMenu}>
                  <div>Send package</div>
                  <div>▸</div>
                </Link>
              </li> */}
              <li className="Header-guestMobile-item">
                <Link to="/sendOrders" onClick={closeMenu}>
                  <div>Package delivery history</div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-guestMobile-item">
                <Link
                  to="/login"
                  onClick={closeMenu}
                  className="Header-guestMobile-login"
                >
                  <div>Login</div>
                  <div>▸</div>
                </Link>
              </li>
              <li className="Header-guestMobile-item">
                <Link
                  to="/register"
                  onClick={closeMenu}
                  className="Button"
                  style={{
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  Sign Up
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <header className="Header">
      <Link
        to="/"
        onClick={() => {
          closeMenu();
          closeSearch();
        }}
      >
        <div className="Header-logo">
          <Logo />
          <span>Deliepoint</span>
        </div>
      </Link>
      <div>
        {UserDesktop()}
        {GuestDesktop()}
        {UserMobile()}
        {GuestMobile()}
      </div>
      {/* <nav>
          <ul>
            {user ? (
              <li>
                <Link to="/profile">{user.firstname}</Link>
                <div>
                  <Link to="/profile">Profile</Link>
                  <Link to="/orders">Orders</Link>
                  <a onClick={logout}>Logout</a>
                </div>
              </li>
            ) : (
              <Link to="/login">Login</Link>
            )}

            <li>
              <Link to="/cart">
                cart{cart.totalCount > 0 && <span>{cart.totalCount}</span>}
              </Link>
            </li>
          </ul>
        </nav> */}
    </header>
  );
}
