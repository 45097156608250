import React, { useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom";
import { getAll, getAllStatus } from "../../services/orderService";
import Title from "../../components/Title/Title";
import DateTime from "../../components/DateTime/DateTime";
import Price from "../../components/Price/Price";
import NotFound from "../../components/NotFound/NotFound";

import "./OrdersPage.scss";

const initialState = {};
const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ALL_STATUS_FETCHED":
      return { ...state, allStatus: payload };
    case "ORDERS_FETCHED":
      return { ...state, orders: payload };
    default:
      return state;
  }
};
export default function OrdersPage() {
  const [{ allStatus, orders }, dispatch] = useReducer(reducer, initialState);

  const { filter } = useParams();

  useEffect(() => {
    getAllStatus().then((status) => {
      dispatch({ type: "ALL_STATUS_FETCHED", payload: status });
    });
    getAll(filter).then((orders) => {
      dispatch({ type: "ORDERS_FETCHED", payload: orders });
    });
  }, [filter]);

  return (
    <div className="OrdersPage">
      <Title title="Order history" />

      {allStatus && (
        <div className="OrdersPage-selectTag">
          <Link to="/orders" className={!filter ? "selected" : ""}>
            All
          </Link>
          {allStatus.map((state) => (
            <Link
              key={state}
              className={state == filter ? "selected" : ""}
              to={`/orders/${state}`}
            >
              {state}
            </Link>
          ))}
        </div>
      )}

      {orders?.length === 0 && (
        <NotFound
          linkRoute={filter ? "/orders" : "/"}
          linkText={filter ? "Show All" : "Go To Home Page"}
        />
      )}

      {orders &&
        orders.map((order) => (
          <div key={order.id} className="OrdersPage-item">
            <div className="OrdersPage-item-top">
              <span>#{order.id}</span>
              <span>
                <DateTime date={order.createdAt} />
              </span>
              <span>{order.status}</span>
            </div>
            <div className="OrdersPage-linkContainer">
              {order.items.map((item) => (
                <Link
                  key={item.food.id}
                  to={`/store/${item.food.parentId}/food/${item.food.id}`}
                >
                  <div className="OrdersPage-link-imageContainer">
                    <img
                      src={item.food.imageUrl}
                      alt={item.food.name}
                      className="OrdersPage-link-image"
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div className="OrdersPage-item-bottom">
              <div>
                <Link
                  to={`/track/${order.id}`}
                  className="OrdersPage-showOrder"
                >
                  Show Order
                </Link>
              </div>
              <div>
                <span>
                  <Price price={order.totalPrice} />
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
