import React from "react";
import { useAuth } from "../../hooks/useAuth";
import Price from "../../components/Price/Price";
import { useForm } from "react-hook-form";
import Input from "../../components/Input/Input";

import { PaystackButton } from "react-paystack";

import "./TopUpPage.scss";
import Title from "../../components/Title/Title";

export default function TopUp() {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useForm();

  const { user, updateBalance } = useAuth();

  const watchBalance = watch("balance");

  const balance = getValues("balance");
  const amount = getValues("balance") * 100;
  const firstname = user.firstname;
  const lastname = user.lastname;
  const phone = user.phone;
  const email = user.email;
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY;

  const componentProps = {
    amount,
    email,
    publicKey,
    metadata: {
      firstname,
      lastname,
      phone,
    },
    text: `Topup with Paystack`,
    onSuccess: () => updateBalance(balance),
  };

  return (
    <div className="TopUpPage">
      <div>
        <Title title="Top up" />
        <div className="TopUpPage-balance">
          Deliepoint balance : <Price price={user.balance} />
        </div>

        <form>
          <Input
            className="TopUp-input"
            type="tel"
            label="How much would you like to add?"
            {...register("balance", {
              required: true,
              minLength: 1,
            })}
            error={errors.balance}
          />
        </form>
        <PaystackButton
          {...componentProps}
          className="Paystack-button Button SendPaymentPage-button"
        />
      </div>
    </div>
  );
}
