import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

import "./LoginPage.scss";
import ReactHelmet from "../../components/ReactHelmet/ReactHelmet";

export default function LoginPage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { user, login } = useAuth();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  useEffect(() => {
    if (!user) return;

    returnUrl ? navigate(returnUrl) : navigate("/");
  }, [user, returnUrl, navigate]);

  const submit = async ({ email, password }) => {
    await login(email, password);
  };

  return (
    <div className="LoginPage">
      <ReactHelmet
        title="Sign in to Deliepoint"
        description="Login to your Deliepoint account. Get whatever you order in minutes!"
      />
      <div className="LoginPage-content">
        <Title title="Login" className="LoginPage-title" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            className="LoginPage-input"
            type="email"
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: "Email Is not valid",
              },
            })}
            error={errors.email}
          />

          <Input
            className="LoginPage-input"
            type="password"
            placeholder="Password"
            {...register("password", {
              required: true,
            })}
            error={errors.password}
          />

          <div className="LoginPage-forgotPasswordRedirect">
            <Link to="/forgotPassword">Forgot your password?</Link>
          </div>

          <Button type="submit" text="Login" className="LoginPage-button" />

          <div className="LoginPage-signupRedirect">
            New user? &nbsp;
            <Link to={`/register${returnUrl ? "?returnUrl=" + returnUrl : ""}`}>
              Register here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
