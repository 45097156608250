import React from "react";
import { Link } from "react-router-dom";

import "./NotFound.scss";

export default function NotFound({ message, linkRoute, linkText }) {
  return (
    <div className="NotFound">
      <span>{message}</span>
      <Link to={linkRoute} className="Button">
        {linkText}
      </Link>
    </div>
  );
}

NotFound.defaultProps = {
  message: "Nothing Found",
  linkRoute: "/",
  linkText: "Go to home page",
};
